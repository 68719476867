import { parseJSON } from 'date-fns';
import { GenericObject, isString } from './extensions';

export const isoDateFormat =
  /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)/;

export function isIsoDateString(value: unknown): boolean {
  return value !== undefined && isString(value) && isoDateFormat.test(value);
}

export function handleDates(body: unknown) {
  if (body === null || body === undefined || typeof body !== 'object')
    return body;

  for (const key of Object.keys(body)) {
    const value = (body as GenericObject)[key] as string;
    if (isIsoDateString(value)) {
      (body as GenericObject)[key] = parseJSON(value);
    } else if (typeof value === 'object') handleDates(value);
  }
}
