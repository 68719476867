import { Button, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const nav = useNavigate();
  return (
    <Card
      elevation={3}
      sx={{
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '2rem'
      }}
    >
      <Typography variant="h1">Uh Oh,</Typography>
      <Typography variant="subtitle1">
        The page you were looking for could not be found.
      </Typography>
      <Button sx={{ mt: '1rem' }} variant="contained" onClick={() => nav(-1)}>
        Go Back
      </Button>
    </Card>
  );
}

export default NotFound;
