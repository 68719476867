import { useMsal } from '@azure/msal-react';
import { GUID } from '../interfaces/guid';
import { AccountInfo } from '@azure/msal-browser';
import { authorities, authorityDomain } from '../config/authConfig';

export const useUser = () => {
  const { instance } = useMsal();
  return instance.getActiveAccount() as AccountInfo;
};

export const useUserId = () => {
  const { instance } = useMsal();
  const localAccountId = (instance.getActiveAccount() as AccountInfo)
    ?.localAccountId;
  if (localAccountId !== undefined) return localAccountId as GUID;
  return null;
};

export const useIsUserBasicRole = () => {
  const { instance } = useMsal();
  return (
    (instance.getActiveAccount() as AccountInfo)?.idTokenClaims?.role === 'User'
  );
};

export const useIsUserSystemAdmin = () => {
  const { instance } = useMsal();
  return (
    (instance.getActiveAccount() as AccountInfo)?.idTokenClaims?.role ===
    'System Admin'
  );
};

export const useIsUserAdmin = () => {
  const { instance } = useMsal();
  const role = (instance.getActiveAccount() as AccountInfo)?.idTokenClaims
    ?.role;
  return role === 'Organisation Admin' || role === 'System Admin';
};

export const useMsalAccount = () => {
  const { instance } = useMsal();

  const account = instance.getActiveAccount() as AccountInfo;
  const acr: string = account?.idTokenClaims?.acr as string; // b2c_1a_signup_signin_roles_split_email_verification
  const environment = account?.environment; // nyaportal.b2clogin.com

  // Decide which authority to use when making a request for an access token
  // (which isn't used in this example, but will be needed for API access).
  let authority: string | undefined = undefined;
  if (environment === authorityDomain) {
    if (acr === 'b2c_1a_signup_signin_roles_split_email_verification_mfa') {
      authority = authorities.signUpSignInMFA.authority;
    } else {
      authority = authorities.signUpSignIn.authority;
    }
  }

  return {
    instance,
    account,
    authority
  };
};
