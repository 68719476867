import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Cookies } from 'react-cookie-consent';

export default function useCookie<T>(
  name: string,
  defaultValue: T
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] {
  const [value, setValue] = useState<T | undefined>(() => {
    const cookie = Cookies.get(name);
    if (cookie) return JSON.parse(cookie) as T;
    Cookies.set(name, JSON.stringify(defaultValue));
    return defaultValue;
  });

  useEffect(() => {
    Cookies.set(name, JSON.stringify(value));
  }, [value]);

  const deleteCookie = useCallback(() => {
    Cookies.remove(name);
    setValue(undefined);
  }, [name]);

  return [value, setValue, deleteCookie];
}
