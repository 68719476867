import { useParams } from 'react-router-dom';
import { Alert, Skeleton } from '@mui/material';
import { NewsItem } from '../../components/newsItems';
import useApi from '../../utils/useApi';
import { INewsItem } from '../../interfaces/newsItem';

const News = () => {
  const { id } = useParams();
  if (id) return <NewsPage id={id} />;
  return null;
};

interface INewsPageProps {
  id: string;
}

const NewsPage = ({ id }: INewsPageProps) => {
  const newsItemApi = useApi<INewsItem>({
    url: `newsItem/${id}`,
    withValidation: false
  });

  if (newsItemApi.data === null && newsItemApi.loading)
    return <Skeleton variant="rectangular" />;
  if (newsItemApi.error && newsItemApi.data == null)
    return (
      <Alert severity="error">Failed to get News: {newsItemApi.error}</Alert>
    );
  if (newsItemApi.data) return <NewsItem newsItem={newsItemApi.data} />;

  return null;
};

export default News;
