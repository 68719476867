import {
  Dialog as MuiDialog,
  DialogProps,
  Theme,
  useMediaQuery
} from '@mui/material';

type IDialogProps = Omit<DialogProps, 'fullScreen' | 'fullWidth'>;

const Dialog = ({
  maxWidth = 'sm',
  children,
  ...rest
}: IDialogProps): JSX.Element => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <MuiDialog
      fullScreen={!isDesktop}
      fullWidth={true}
      maxWidth={maxWidth}
      {...rest}
    >
      {children}
    </MuiDialog>
  );
};

export default Dialog;
