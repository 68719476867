import { Box, Divider, Link, styled, Theme, Typography, useMediaQuery } from '@mui/material';
import { Constants } from '../config/constants';
import nyaLogo from '../assets/nya-logo.png';
import { Link as RouterLink } from 'react-router-dom';

function Footer() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const links = (
    <Box sx={{ flexGrow: 1, mt: { xs: 2, sm: 0 } }}>
      <Link
        underline='hover'
        color='inherit'
        to='/privacyPolicy'
        component={RouterLink}
      >
        Privacy Policy
      </Link>
    </Box>
  );

  return (
    <FooterBackground>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row'
          }
        }}
      >
        <Box sx={{ flex: '1 1 auto', alignSelf: 'center' }}>
          <Link
            underline='hover'
            color='inherit'
            variant='h6'
            component='a'
            href='https://www.nya.org.uk/'
            target='_blank'
            sx={{ flexGrow: 1, padding: '1rem 0' }}
          >
            Powered by
            <Box
              sx={{
                ml: 1,
                maxHeight: {
                  xs: '2rem',
                  md: '3rem'
                },
                verticalAlign: 'middle'
              }}
              component='img'
              src={nyaLogo}
              alt='NYA'
            />
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            mt: { xs: 2, sm: 0 }
          }}
        >
          {isDesktop && links}
          <Box sx={{ flexGrow: 1 }}>
            <Typography>
              <b>Contact</b>
            </Typography>
            <Link
              underline='hover'
              color='inherit'
              component='a'
              href='tel:01162427350'
            >
              T: 0116 242 7350
            </Link>
            <br />
            <Link
              underline='hover'
              color='inherit'
              component='a'
              href='mailto:nya@nya.org.uk '
            >
              E: nya@nya.org.uk
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography>9 Newarke Street</Typography>
            <Typography>Leicester</Typography>
            <Typography>LE1 5SN</Typography>
          </Box>
        </Box>
        {!isDesktop && links}
      </Box>
      <Divider
        sx={{ mt: 1, mb: 1, height: '4px' }}
        color={Constants.secondaryColors.main}
        orientation='horizontal'
      />
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: '1 1 auto' }}>
          <Typography variant='overline'>
            © Youth Work One {new Date().getFullYear()}
          </Typography>
        </Box>
        <Box sx={{ color: 'primary.dark' }}>
          <Link
            underline='hover'
            color='inherit'
            component='a'
            href='https://gather.tech/'
            target='_blank'
          >
            built by Gather Tech
          </Link>
        </Box>
      </Box>
    </FooterBackground>
  );
}

const FooterBackground = styled('footer')(({ theme }) => {
  return `
        flex: 0 1 6rem;
        width: 100%;
        height: auto;
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
        box-shadow: 0 0 0 100vmax ${theme.palette.primary.main};
        clip-path: inset(0 -100vmax);
        max-width: ${Constants.bodyMaxWidth};
        margin: 0 auto;
        padding: 1rem;
        box-sizing: border-box;
        
        ${theme.breakpoints.down('sm')} {
          flex: 0 0 0;
        }
`;
});

export default Footer;
