import { useUser } from '../utils/userHooks';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../config';
import { Fab, styled } from '@mui/material';
import { ContactSupport } from '@mui/icons-material';

interface IChatBot {
  __be: {
    id: string;
  };
  BE_API: {
    onLoad: () => void;
    onBeforeLoad: () => boolean | void;
    hideChatWindow: () => void;
    onChatWindowOpen: () => void;
    openChatWindow: () => void;
    onChatWindowClose: () => void;
    setUserAttributes: (config: unknown) => void;
    create: () => void;
    destroy: () => void;
    resetSession: () => void;
  };
}

const ChatBot = () => {
  if (Config.chatbotId === '') return null;

  // const [show, setShow] = useState(true);
  // const isAuthenticated = useIsAuthenticated();
  const user = useUser();
  const w = window as unknown as IChatBot;

  useEffect(() => {
    w.__be = w.__be || {};
    w.__be.id = Config.chatbotId;
  }, []);

  useEffect(() => {
    w.BE_API = w.BE_API || {};
    w.BE_API.onLoad = () => {
      // console.info('Chatbot: onLoad', user?.idTokenClaims);
      // if (!user?.idTokenClaims) {
      //  w.BE_API.hideChatWindow();
      // }
      console.log('Chatboat loaded. Setting user attributes', user);
      w.BE_API.setUserAttributes({
        id: user?.idTokenClaims?.sub,
        // eslint-disable-next-line camelcase
        default_username: user?.idTokenClaims?.email,
        // eslint-disable-next-line camelcase
        default_email: user?.idTokenClaims?.email,
        // eslint-disable-next-line camelcase
        default_name: user?.idTokenClaims?.name
      });
    };
    w.BE_API.onChatWindowOpen = () => {
      console.log('Chatbot: Open dialog');
      // setShow(false);
    };
    w.BE_API.onChatWindowClose = () => {
      console.log('Chatbot: Close dialog');
      // setShow(true);
      w.BE_API.hideChatWindow();
    };
  }, []);

  useEffect(() => {
    // console.log('Chatbot: isAuthenticated changed', user?.idTokenClaims);
    if (w.BE_API !== undefined) {
      if (user?.idTokenClaims === undefined && w.BE_API.resetSession) {
        console.log('Chatbot: Reset session');
        w.BE_API.resetSession();
      } else if (w.BE_API.setUserAttributes) {
        console.log('Chatbot: Setting user attributes', user);
        w.BE_API.setUserAttributes({
          id: user?.idTokenClaims?.sub,
          // eslint-disable-next-line camelcase
          default_username: user?.idTokenClaims?.email,
          // eslint-disable-next-line camelcase
          default_email: user?.idTokenClaims?.email,
          // eslint-disable-next-line camelcase
          default_name: user?.idTokenClaims?.name
        });
      }
    }
  }, [user?.idTokenClaims?.sub]);

  const startChat = () => {
    // console.info('Chatbot: Button Click');
    // console.info('Chatbot: Set Session Attributes');
    // w.BE_API.setUserAttributes({
    //   id: user?.idTokenClaims?.sub,
    //   // eslint-disable-next-line camelcase
    //   default_username: user?.idTokenClaims?.email,
    //   // eslint-disable-next-line camelcase
    //   default_email: user?.idTokenClaims?.email,
    //   // eslint-disable-next-line camelcase
    //   default_name: user?.idTokenClaims?.name
    // });
    w.BE_API.openChatWindow();
  };
  // if (!isAuthenticated) return null;

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          async={true}
          src={
            ('https:' == document.location.protocol ? 'https://' : 'http://') +
            'cdn.chatbot.com/widget/plugin.js'
          }
        />
      </Helmet>

      <FloatingActionButtonArea>
        <Fab
          color="secondary"
          aria-label="Support Chat"
          onClick={startChat}
          variant="extended"
        >
          <ContactSupport />
          Support
        </Fab>
      </FloatingActionButtonArea>
    </>
  );
};

const FloatingActionButtonArea = styled('div')(({ theme }) => ({
  zIndex: 1,
  position: 'fixed',
  right: theme.spacing(2),
  bottom: theme.spacing(2)
}));

export default ChatBot;
