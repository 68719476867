import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react';
import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus
} from '@azure/msal-browser';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  authorities,
  authorityDomain,
  loginRequest
} from '../config/authConfig';
import { Typography } from '@mui/material';

function RequireAuth({
  children,
  requireAdmin,
  adminOverride,
  requireMFA
}: {
  children: JSX.Element;
  requireAdmin?: boolean;
  adminOverride?: JSX.Element;
  requireMFA?: boolean;
}) {
  const { instance, inProgress } = useMsal();

  const account = instance.getActiveAccount() as AccountInfo;
  const environment: string = account?.environment as string; // nyaportal.b2clogin.com
  const acr: string = account?.idTokenClaims?.acr as string; // b2c_1a_signup_signin_roles_split_email_verification
  const isAdmin: boolean = account?.idTokenClaims?.role === 'System Admin';
  const loggedIn: boolean = account ? true : false;

  // 'idp' seems to be linked to the identity provider.
  // If using a microsoft account, this will be 'https://login.live.com'
  // For B2C setup profiles, this field appears to be empty.
  const idp = account?.idTokenClaims?.idp;

  const doUpgrade: boolean =
    !idp &&
    ((requireMFA ?? false) || (requireAdmin ?? false)) &&
    (!loggedIn ||
      (environment === authorityDomain &&
        acr === 'b2c_1a_signup_signin_roles_split_email_verification'));

  // Decide which authority to use when making a request for an access token
  // (which isn't used in this example, but will be needed for API access).
  let authority: string | null = null;
  if (environment === authorityDomain) {
    if (acr === 'b2c_1a_signup_signin_roles_split_email_verification_mfa') {
      authority = authorities.signUpSignInMFA.authority;
    } else {
      authority = authorities.signUpSignIn.authority;
    }
  }

  async function verifyAuth() {
    const account = instance.getActiveAccount();
    if (!account) {
      throw Error('No active account');
    }

    if (doUpgrade) {
      throw Error('Upgrade needed');
    }

    if (!authority) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account
      });

      return response;
    } else {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
        authority: authority
      });

      return response;
    }
  }

  useEffect(() => {
    verifyAuth().catch((e) => {
      if (e instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({
          ...loginRequest,
          account: account
        });
      } else {
        console.warn(e);

        // It's safe for us to issue a login redirect request here once
        // the interaction status from MSAL is NONE. This has the effect
        // of logging the user in directly without needing the "sign in"
        // button.
        if (inProgress === InteractionStatus.None) {
          if (doUpgrade) {
            // Force a re-login with MFA credentials
            instance.loginRedirect({
              ...loginRequest,
              authority: authorities.signUpSignInMFA.authority,
              prompt: 'login'
            });
          } else {
            instance.loginRedirect(loginRequest);
          }
        }
      }
    });
  }, [instance, doUpgrade]);

  useEffect(() => {
    if (!loggedIn) {
      if (requireMFA) {
        instance.loginRedirect({
          ...loginRequest,
          authority: authorities.signUpSignInMFA.authority,
          prompt: 'login'
        });
      } else {
        instance.loginRedirect(loginRequest);
      }
    }
  }, []);

  if (requireAdmin && !isAdmin) {
    return <Navigate to="/403" />;
  }

  return (
    <>
      <AuthenticatedTemplate>
        {doUpgrade ? null : isAdmin && adminOverride ? adminOverride : children}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Typography variant="h3">Please sign in.</Typography>
        <p>
          An account is required to see this portion of the site. Please sign-up
          or log-in using the &quot;Sign In&quot; button above.
        </p>
      </UnauthenticatedTemplate>
    </>
  );
}

export default RequireAuth;
