import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { IOption } from '../interfaces/objects';
import { GUID } from '../interfaces/guid';
import { IAddress } from '../interfaces/address';

export type NonUndefined<T> = T extends undefined ? never : T;

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export type GenericObject<T = unknown> = { [key: string]: T };

/* export const GetPropertyValue = <T>(obj: T, dataToRetrieve: string) => {
    return dataToRetrieve
        .split('.') // split string based on `.`
        .reduce(function (o, k) {
            return o && o[k]; // get inner property if `o` is defined else get `o` and return
        }, obj); // set initial value as object
}*/

export const getPropertyValue = <T, K extends keyof T>(
  obj: T,
  key: K
): T[K] => {
  return obj[key];
};

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isString = (obj: unknown): obj is string => {
  return typeof obj === 'string' || obj instanceof String;
};

export const isNumber = (obj: unknown): obj is number => {
  return typeof obj === 'number' || obj instanceof Number;
};

export const isBoolean = (obj: unknown): obj is boolean => {
  return typeof obj === 'boolean' || obj instanceof Boolean;
};

export const isJsonObject = (str: unknown) => {
  try {
    JSON.parse(str as string);
  } catch (e) {
    return false;
  }
  return true;
};

export const isGuid = (str: unknown): str is GUID => {
  if (isString(str)) {
    return /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(
      str
    );
  }
  return false;
};

/*

type Join<K, P> = K extends string | number ?
    P extends string | number ?
        `${K}${"" extends P ? "" : "."}${P}`
        : never : never;

export type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";*/

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string, sx?: SxProps<Theme>) {
  return {
    sx: { ...sx, bgcolor: stringToColor(name) },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  };
}

export function count<T extends Record<K, unknown>, K extends keyof T>(
  arr: T[],
  field: K,
  value: unknown
): number {
  return arr.reduce((counter, obj) => {
    if (obj[field] === value) counter += 1;
    return counter;
  }, 0);
}

export const toIOptions = (values: string[]): IOption[] => {
  if (values === undefined || values.length === 0) return [];
  return values.map((v) => {
    return { text: v, value: v } as IOption;
  });
};

export function isEmpty<T extends Record<K, unknown>, K extends keyof T>(
  obj: T
): boolean {
  return Object.keys(obj).length === 0;
}

export function formatAddress(address: IAddress): string {
  const parts: string[] = [];
  parts.push(address.street);
  if (address.city !== null && address.city !== '') {
    parts.push(address.city);
  }
  if (address.county !== null && address.county !== '') {
    parts.push(address.county);
  }
  parts.push(address.postcode);
  return parts.join(', ');
}

export function booleanToTrueFalse(bool: boolean | undefined): string {
  if (bool === true) return 'True';
  return 'False';
}
