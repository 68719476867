import { RefObject, useEffect } from 'react';

const useSvgElementOnClick = <T extends SVGElement>(
  ref: RefObject<T | undefined>,
  watcherClass: string,
  callback: () => void,
  maxDepth = 1
): void => {
  const setup = (ev: Event) => {
    checkAllElements(
      (ev as PointerEvent).clientX,
      (ev as PointerEvent).clientY
    );
  };

  const checkAllElements = (x: number, y: number) => {
    const items = document.elementsFromPoint(x, y);
    if (items) {
      if (checkChildren(items as T[])) {
        callback();
      }
    }
  };

  const checkChildren = (children: T[], level = 0): boolean => {
    for (const c of children) {
      if (c.classList.contains(watcherClass)) {
        return true;
      } else {
        if (c.children && level <= maxDepth) {
          if (checkChildren(c.children as unknown as T[], level++)) return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('click', setup);
    }

    return () => {
      ref.current?.removeEventListener('click', setup);
    };
  }, [ref.current]);
};

export default useSvgElementOnClick;
