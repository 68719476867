import { useLocation } from 'react-router-dom';
import { usePrevious } from './usePrevious';
import { useTracking as useReactTracking } from 'react-tracking';
import { useEffect } from 'react';

export type ITrackingData =
  | INavigationTrackingData
  | ISearchTrackingData
  | IOpenTrackingData;

interface INavigationTrackingData {
  action: 'navigate';
  to: string;
  from: string;
}

interface ISearchTrackingData {
  action: 'search';
  page: string;
  value: string;
}

interface IOpenTrackingData {
  action: 'open';
  value: string;
}

export const useLocationTracking = () => {
  const location = useLocation();
  const previousLocation = usePrevious(location);

  const trackEvent = useTracking();

  useEffect(() => {
    if (location.pathname !== previousLocation?.pathname)
      trackEvent({
        action: 'navigate',
        to: location.pathname,
        from: previousLocation?.pathname
      });
  }, [location]);

  // useBeforeUnload(() => alert('Navigating away'));
};

export const useTracking = () => {
  const { trackEvent } = useReactTracking<ITrackingData>();
  return trackEvent;
};
