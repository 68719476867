import { RefObject, useEffect, useState } from 'react';

const useIntersection = <T extends Element>(
  element: RefObject<T>,
  rootMargin: string
): boolean => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    element.current && observer.observe(element.current);

    return () => {
      if (element.current) observer.unobserve(element.current);
    };
  }, []);

  return isVisible;
};

export default useIntersection;
