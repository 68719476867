import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/* function getSessionStorage<T>(key: string): T | undefined {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
        return undefined;
    }
    return JSON.parse(stored) as T;
}*/

function getSessionStorageOrDefault<T>(key: string, defaultValue: T): T {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  if (stored === 'undefined') return defaultValue;
  return JSON.parse(stored);
}

function useCachedState<T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(
    getSessionStorageOrDefault<T>(key, defaultValue)
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

/* export function useCachedStateFunc<T>(key: string, callback: Function): T | undefined {
    const [value, setValue] = useState(getSessionStorage<T>(key));

    useEffect(() => {
        setValue(callback());
    }, [callback])

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }, [key, value])

    return value
}*/

export default useCachedState;
