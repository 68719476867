import { useMsal } from '@azure/msal-react';
import { Button, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { IPublicClientApplication } from '@azure/msal-browser';
import {
  changeMFAPhoneNumberRequest,
  changePasswordRequest,
  loginRequest
} from '../config/authConfig';
import { Dialpad, Login, Logout, Password } from '@mui/icons-material';
import { Path, To, useLocation, useNavigate } from 'react-router-dom';
import { GenericObject } from '../utils/extensions';

export function handleSignIn(
  instance: IPublicClientApplication,
  callback?: () => void
) {
  instance
    .loginRedirect(loginRequest)
    .then(() => {
      if (callback) callback();
    })
    .catch((e) => {
      console.error('SignIn Error', e);
    });
}

export function handleSignOut(
  instance: IPublicClientApplication,
  dontClearStorage?: boolean
) {
  instance
    .logoutRedirect({ postLogoutRedirectUri: '/' })
    .then(() => {
      if (!dontClearStorage) {
        sessionStorage.clear();
        localStorage.clear();
      }
    })
    .catch((e) => {
      console.error(e);
    });
}

function handleChangePassword(instance: IPublicClientApplication) {
  instance.loginRedirect(changePasswordRequest).catch((e) => {
    console.error(e);
  });
}

function handleChangeMFAPhoneNumber(instance: IPublicClientApplication) {
  instance.loginRedirect(changeMFAPhoneNumberRequest).catch((e) => {
    console.error(e);
  });
}

/* function handleError(event: EventMessage, instanace: IPublicClientApplication){
    if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
            if (event.interactionType === InteractionType.Redirect) {
                instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
            } else if (event.interactionType === InteractionType.Popup) {
                instance.loginPopup(b2cPolicies.authorities.forgotPassword)
                    .catch(e => {
                        return;
                    });
            }
        }
    }
}*/

export const SignInButton = ({ showText = true }: { showText?: boolean }) => {
  const { instance } = useMsal();
  const { state } = useLocation();
  const navigate = useNavigate();
  const from: Path =
    ((state as GenericObject)?.from as Path) ?? ({ pathname: '/' } as Path);

  const callBack = () => {
    navigate(from as To);
  };

  return (
    <Button
      sx={{ color: 'common.white' }}
      onClick={() => handleSignIn(instance, callBack)}
    >
      <Login sx={{ marginRight: '5px' }} />
      {showText && 'Sign In'}
    </Button>
  );
};

export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <MenuItem onClick={() => handleSignOut(instance)}>
      <ListItemIcon>
        <Logout />
      </ListItemIcon>
      <ListItemText>Sign Out</ListItemText>
    </MenuItem>
  );
};

export const ChangePasswordMenuItem = () => {
  const { instance } = useMsal();

  return (
    <MenuItem onClick={() => handleChangePassword(instance)}>
      <ListItemIcon>
        <Password />
      </ListItemIcon>
      <ListItemText>Password</ListItemText>
    </MenuItem>
  );
};

export const ChangePasswordButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="contained"
      onClick={() => handleChangePassword(instance)}
      startIcon={<Password />}
    >
      Change Password
    </Button>
  );
};

export const ChangeMFAPhoneNumberButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="contained"
      onClick={() => handleChangeMFAPhoneNumber(instance)}
      startIcon={<Dialpad />}
    >
      Change MFA Phone Number
    </Button>
  );
};
