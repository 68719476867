import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  AuthenticationResult,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/authConfig';
import { BrowserRouter } from 'react-router-dom';
import PreferencesContextWrapper from './utils/PreferencesContext';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';
import { store } from './store';
import { Provider } from 'react-redux';
import React from 'react';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize();

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const account = msalInstance.getAllAccounts()[0];
  console.log('Default to first: ', account);
  msalInstance.setActiveAccount(account);
}

msalInstance.addEventCallback((e) => {
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/a81fcef3d82523e03828d91bb0ee8d2ab2cc20d8/samples/msal-react-samples/typescript-sample/src/index.tsx#L22
  const payload = e.payload as AuthenticationResult;
  if (payload && payload.account) {
    if (e.eventType === EventType.LOGIN_SUCCESS) {
      // Login success always sets the account.
      msalInstance.setActiveAccount(payload.account);
    } else if (e.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
      // Acquiring a new token when already logged in ONLY sets the new account
      // if it is a "higher" level (i.e. an upgrade to MFA) than the current
      // one.
      const acr: string = payload.account.idTokenClaims?.acr as string;
      if (acr === 'b2c_1a_signup_signin_roles_split_email_verification_mfa') {
        msalInstance.setActiveAccount(payload.account);
      }
    }
  }
});

msalInstance.enableAccountStorageEvents();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enGB}
          >
            <PreferencesContextWrapper>
              <SnackbarProvider>
                <App />
              </SnackbarProvider>
            </PreferencesContextWrapper>
          </LocalizationProvider>
        </Provider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
