import config from '../config';
import { Alert } from '@mui/material';

const EnvironmentNotification = () => {
  if (config.environmentMessage !== undefined) {
    return (
      <Alert
        variant="filled"
        severity="error"
        sx={{
          position: 'fixed',
          zIndex: 999,
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          borderRadius: '0 0 4px 4px'
        }}
      >
        {config.environmentMessage}
      </Alert>
    );
  }
  return null;
};
export default EnvironmentNotification;
