import { Box, Typography } from '@mui/material';
import NewsItems from '../components/newsItems';
import HomeTiles from '../components/homeTiles';
import { useMsal } from '@azure/msal-react';

function Home() {
  return (
    <Box
      sx={{
        paddingTop: 0,
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Welcome />
      <HomeTiles />
      <NewsItems />
    </Box>
  );
}

const Welcome = () => {
  const { accounts } = useMsal();
  const username = accounts[0] && accounts[0].name;

  if (!username) return null;

  return (
    <Typography variant='h6' pb={1}>
      Welcome <b>{username}</b>
    </Typography>
  );
};

export default Home;
