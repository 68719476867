import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import Config from './index';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const userFlows = {
  signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN_ROLES_SPLIT_EMAIL_VERIFICATION',
  signUpSignInMFA: 'B2C_1A_SIGNUP_SIGNIN_ROLES_SPLIT_EMAIL_VERIFICATION_MFA',
  changePassword: 'B2C_1A_PROFILECHANGEPASSWORD',
  changeMFAPhoneNumber: 'B2C_1A_ProfileChangeMFAPhoneNumber'
};

export const authorityDomain = `${Config.authorityDomain}.b2clogin.com`;

export const authorities = {
  signUpSignIn: {
    authority: `https://${authorityDomain}/${Config.authorityDomain}.onmicrosoft.com/${userFlows.signUpSignIn}`
  },
  signUpSignInMFA: {
    authority: `https://${authorityDomain}/${Config.authorityDomain}.onmicrosoft.com/${userFlows.signUpSignInMFA}`
  },
  changePassword: {
    authority: `https://${authorityDomain}/${Config.authorityDomain}.onmicrosoft.com/${userFlows.changePassword}`
  },
  changeMFAPhoneNumber: {
    authority: `https://${authorityDomain}/${Config.authorityDomain}.onmicrosoft.com/${userFlows.changeMFAPhoneNumber}`
  }
};

export const msalConfig = {
  auth: {
    clientId: Config.clientId,
    authority: authorities.signUpSignIn.authority,
    knownAuthorities: [authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: isIE // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(
        logLevel: LogLevel,
        message: string,
        containsPii: boolean
      ) {
        if (containsPii) {
          return;
        }
        switch (logLevel) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    }
  }
} as Configuration;

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: []
} as RedirectRequest;

export const apiRequest = {
  scopes: [
    `https://${Config.authorityDomain}.onmicrosoft.com/${Config.apiClientId}/API.ReadWrite`
  ]
} as SilentRequest;

export const changePasswordRequest = {
  authority: authorities.changePassword.authority
} as RedirectRequest;

export const changeMFAPhoneNumberRequest = {
  authority: authorities.changeMFAPhoneNumber.authority
} as RedirectRequest;
