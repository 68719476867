import { Fragment } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { FormatQuoteRounded } from '@mui/icons-material';
import { Image } from 'mui-image';
import { Constants } from '../config/constants';
import useApi from '../utils/useApi';
import { INewsItem } from '../interfaces/newsItem';

const NewsItems = () => {
  const newsItemsApi = useApi<{ newsItems: INewsItem[] }>({
    url: 'newsItem/recent',
    withValidation: false
  });

  if (
    newsItemsApi.loading ||
    (newsItemsApi.error && newsItemsApi.data == null) ||
    newsItemsApi.data?.newsItems.length === 0
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      <TopQuote />
      <Background>
        <BottomQuote />
        <Grid container spacing={4}>
          {newsItemsApi.data?.newsItems.map((newsItem) => {
            return (
              <Grid key={newsItem.id.toString()} xs={12} sm={6}>
                <NewsItem newsItem={newsItem} preview />
              </Grid>
            );
          })}
        </Grid>
      </Background>
    </Box>
  );
};

const Background = styled(Box)(
  ({ theme }) => `
      position: relative;
      margin-bottom: ${theme.spacing(-2)};
      padding-top: ${theme.spacing(2)};
      padding-bottom: ${theme.spacing(4)};
      background: ${theme.palette.blue.main};
      box-shadow: 0 0 0 100vmax ${theme.palette.blue.main};
      clip-path: inset(0 -100vmax);
`
);

const TopQuote = styled(FormatQuoteRounded)(
  ({ theme }) => `
      position: absolute;
      font-size: 5rem;
      top: 0;
      left: 0;
      transform: translateY(-70%) rotate(180deg);
      color: ${theme.palette.blue.main};
`
);

const BottomQuote = styled(FormatQuoteRounded)(
  ({ theme }) => `
      position: absolute;
      font-size: 5rem;
      bottom: 0;
      right: 0;
      transform: translateY(30%);
      color: ${theme.palette.primary.main};
`
);

interface INewsItemProps {
  newsItem: INewsItem;
  preview?: boolean;
}

export const NewsItem = ({ newsItem, preview = false }: INewsItemProps) => {
  const publishedDate = (
    <Typography
      variant="overline"
      component="div"
      display="block"
      sx={{ fontStyle: 'italic', fontSize: '0.7rem', textAlign: 'right' }}
      gutterBottom
    >
      {format(newsItem.publishedDate, 'P')}
    </Typography>
  );
  return (
    <Box>
      <Typography variant="h5">{newsItem.title}</Typography>
      <Typography variant="subtitle2">
        {newsItem.subTitle ? (
          <Fragment>
            {newsItem.subTitle}
            <br />
            {publishedDate}
          </Fragment>
        ) : (
          publishedDate
        )}
      </Typography>

      <Image
        src={newsItem.imageUrl}
        showLoading={<CircularProgress color="secondary" />}
        wrapperStyle={{ margin: '0 auto' }}
      />

      <Markdown options={Constants.markdownOptions}>
        {newsItem.body.length > 250 && preview
          ? `${newsItem.body.substring(0, 250)}...`
          : newsItem.body}
      </Markdown>

      {preview && (
        <Button component={Link} to={`/news/${newsItem.id}`}>
          Read more...
        </Button>
      )}
    </Box>
  );
};

export default NewsItems;
