import { createContext, ReactNode, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';

export type ThemeMode = 'Light' | 'Dark' | 'System';

export interface IPreferencesContext {
  themeMode: ThemeMode;
  setThemeMode: (mode: ThemeMode) => void;
}

export const defaultPreferences: IPreferencesContext = {
  themeMode: 'Light',
  setThemeMode() {
    return;
  }
};

export const PreferencesContext =
  createContext<IPreferencesContext>(defaultPreferences);

interface IPreferencesContextWrapperProps {
  children: ReactNode;
}

const PreferencesContextWrapper = ({
                                     children
                                   }: IPreferencesContextWrapperProps) => {
  const { instance } = useMsal();

  const getInit = () => {
    const currentAccount = instance.getActiveAccount() as AccountInfo;

    return (currentAccount?.idTokenClaims?.portalTheme ?? 'Light') as ThemeMode;
  };

  const [themeMode, setThemeMode] = useState<ThemeMode>(() => getInit());

  useEffect(() => {
    const currentAccount = instance.getActiveAccount() as AccountInfo;
    if (currentAccount && currentAccount.idTokenClaims) {
      setThemeMode(
        (currentAccount.idTokenClaims.portalTheme ?? 'Light') as ThemeMode
      );
    }
  }, [instance]);

  return (
    <PreferencesContext.Provider value={{ themeMode, setThemeMode }}>
      {children}
    </PreferencesContext.Provider>
  );
};

export default PreferencesContextWrapper;
