interface configData {
  clientId: string;
  apiClientId: string;
  environmentMessage?: string;
  apiURL: string;
  crmApiURL: string;
  authorityDomain: string;
  chatbotId: string;
  azureMapsKey: string;
  cdn: string;
}

const local: configData = {
  clientId: 'ac6a3e0d-2f9d-4ed1-981c-c4c9a3ba9826',
  apiClientId: 'd7212ac0-c212-45c3-b2d8-cd0e0577af8c',
  environmentMessage: 'Warning: You are in a Local environment!',
  authorityDomain: 'nyaportal',
  apiURL: 'https://localhost:7233/api',
  crmApiURL:
    'https://api-eu-west-2.graphcms.com/v2/cl5132oij1ag501t3439g2fnx/uat',
  chatbotId: '',
  azureMapsKey: 'dOAZ8dfS50LRs5yaZc-3ZmSHh-TOl5jmaiy1N2qxlwc',
  cdn: 'https://cdn-uat-ywo.azureedge.net'
};

const development: configData = {
  clientId: 'ac6a3e0d-2f9d-4ed1-981c-c4c9a3ba9826',
  apiClientId: 'd7212ac0-c212-45c3-b2d8-cd0e0577af8c',
  environmentMessage: 'Warning: This is the UAT environment!',
  authorityDomain: 'nyaportal',
  apiURL: 'https://api-uat-nya-portal-001.azurewebsites.net/api',
  crmApiURL:
    'https://api-eu-west-2.graphcms.com/v2/cl5132oij1ag501t3439g2fnx/uat',
  chatbotId: '',
  azureMapsKey: 'dOAZ8dfS50LRs5yaZc-3ZmSHh-TOl5jmaiy1N2qxlwc',
  cdn: 'https://cdn-uat-ywo.azureedge.net'
};

const production: configData = {
  clientId: '677e5708-0cf8-4717-a66c-4672e7f99ce8',
  apiClientId: '48669b71-6f66-4410-9ef5-d2aebc1592da',
  authorityDomain: 'youthworkone',
  apiURL: 'https://api.youthworkone.org.uk/api',
  crmApiURL:
    'https://api-eu-west-2.graphcms.com/v2/cl5132oij1ag501t3439g2fnx/master',
  chatbotId: '6405cfe52f353000074bfe9d',
  azureMapsKey: 'RJERhXnYqtDJAF5nU-Y8z73mUWz_nrIo7Tpri8_KH7Q',
  cdn: 'https://cdn-live-ywo.azureedge.net'
};

const config: configData =
  process.env.REACT_APP_STAGE === undefined
    ? local
    : process.env.REACT_APP_STAGE === 'production'
    ? production
    : development;

export default config;
