import { RefObject, useEffect } from 'react';

const useSvgElementOnEnter = <T extends SVGElement>(
  ref: RefObject<T | undefined>,
  watcherClass: string,
  callback: () => void,
  maxDepth = 1
): void => {
  const setup = (ev: KeyboardEvent) => {
    if (ev.key === 'Enter') {
      const { target } = ev;
      const { children } = target as T;

      if (checkChildren(children as unknown as T[])) callback();
    }
  };

  const checkChildren = (children: T[], level = 0): boolean => {
    for (const c of children) {
      if (c.classList.contains(watcherClass)) {
        return true;
      } else {
        if (c.children && level <= maxDepth) {
          if (checkChildren(c.children as unknown as T[], level++)) return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('keypress', setup);
    }

    return () => {
      ref.current?.removeEventListener('keypress', setup);
    };
  }, [ref.current]);
};

export default useSvgElementOnEnter;
