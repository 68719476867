const _pColors = {
  main: '#4e4d4d',
  light: '#717070',
  veryLight: '#e0e0e0',
  dark: '#363535',
  contrastText: '#fff'
};
const _sColors = {
  main: '#F39C20',
  light: '#F5AF4C',
  dark: '#AA6D16',
  contrastText: '#000'
};
const _blueColorsDark = {
  main: '#4D9ACC',
  light: '#70AED6',
  dark: '#356B8E',
  contrastText: '#fff'
};
const _blueColorsLight = {
  main: '#CCE9F3',
  light: '#D6EDF5',
  dark: '#8EA3AA',
  contrastText: '#000'
};
export const Constants = {
  dateFormats: {
    ukDateTime: 'dd/MM/yyyy HH:mm',
    ukDateOnly: 'dd/MM/yyyy'
  },
  primaryColors: _pColors,
  secondaryColors: _sColors,
  blueColorsDark: _blueColorsDark,
  blueColorsLight: _blueColorsLight,
  bodyPadding: {
    xs: '1rem',
    sm: '1rem 2rem',
    lg: '1rem 5rem',
    xl: '1rem 30rem'
  },
  bodyMaxWidth: '1250px',
  mobileDeviceBreakpoint: '(min-width:600px)',
  sxDefaultCardHeader: {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    clipPath: 'polygon(0% 0%,100% 0%, 100% calc(100% - 15px), 0% 100%)'
  },
  sxDefaultCardContent: {
    '& p': {
      '&:first-of-type': {
        marginTop: 0
      },
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  sxResourceLinkCard: {
    color: 'primary.contrastText',
    backgroundColor: 'primary.light',
    background: `linear-gradient(90deg, #020024 0%, ${_pColors.main} 35%, ${_pColors.light} 100%);`
  },
  sxModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 1,
    width: '60vw',
    maxWidth: '1000px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  },
  markdownOptions: {
    overrides: {
      h1: {
        props: {
          className: 'MuiTypography-h1'
        }
      },
      h2: {
        props: {
          className: 'MuiTypography-h2'
        }
      },
      h3: {
        props: {
          className: 'MuiTypography-h3'
        }
      },
      h4: {
        props: {
          className: 'MuiTypography-h4'
        }
      },
      h5: {
        props: {
          className: 'MuiTypography-h5'
        }
      },
      h6: {
        props: {
          className: 'MuiTypography-h6'
        }
      },
      p: {
        props: {
          className: 'MuiTypography-paragraph'
        }
      },
      ul: {
        props: {
          className: 'MuiList-root MuiList-padding'
        }
      },
      ol: {
        props: {
          className: 'MuiList-root MuiList-padding'
        }
      },
      li: {
        props: {
          className: 'MuiListItem-root MuiListItem-padding'
        }
      }
    }
  },
  regex: {
    
    phoneNumber:
      /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/,
    
    postCode:
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/,
    
    website:
    /^((https|http):\/\/)?([^:/?#[\].]+)(\.[^:/?#[\].]+){1,256}(\/+[^:/?#[\]]+){0,256}\/?(\?[^?&=]+(=[^?&=]*)?(&[^?&=]*(=[^?&=]*)?)*)?(#[^?#]*)?(\?|#|&)?$/,
    
    uniqueOrganisationId:
      /^NYA[-][0-9]{5}[-]+[0-9]{4}$/
  }
};
