import { CookieConsent } from 'react-cookie-consent';
import { Button, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

/* const CookiePolicy = () => {
  const [show, setShow] = useState(true);

  if (!show) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100vw',
        backgroundColor: 'background.paper',
        color: 'text.primary',
        textAlign: 'center',
        padding: 4
      }}
      boxShadow={24}
    >
      Test Content
      <Button
        variant='outlined'
        component={Link}
        to='/privacyPolicy'
        sx={{ margin: '0 auto', mt: 2, maxWidth: '250px', width: '100%' }}
      >
        Privacy Policy
      </Button>
      <Button
        variant='contained'
        color='secondary'
        sx={{ margin: '0 auto', mt: 2, maxWidth: '250px', width: '100%' }}
      >
        Accept
      </Button>
    </Box>
  );
};*/

const CookiePolicy = () => {
  return (
    <CookieConsent
      disableButtonStyles={true}
      buttonText='Accept'
      buttonStyle={{ flex: '0 0 auto', padding: '5px 10px', margin: '15px' }}
      ButtonComponent={Button}
      customButtonProps={{ variant: 'contained', color: 'secondary' }}
    >
      This website uses cookies to enhance the user experience.{' '}
      <Link color='inherit' component={RouterLink} to='/privacyPolicy'>
        Privacy Policy
      </Link>
    </CookieConsent>
  );
};

export default CookiePolicy;
