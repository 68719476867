import { Backdrop, Box, CircularProgress } from '@mui/material';

const SuspenseLoader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        minHeight: '40px',
        margin: 'auto'
      }}
    >
      <Box
        sx={{
          top: '50%',
          left: '50%',
          position: 'absolute',
          transform: 'translate(-50%,-50%)'
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    </Box>
  );
};

export const OverlayLoader = () => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
  );
};

interface IElementLoading {
  loading: boolean;
  showLoader?: boolean;
  children: JSX.Element | JSX.Element[];
}

export const ElementLoading = ({
  loading,
  showLoader = true,
  children
}: IElementLoading): JSX.Element => {
  if (loading) {
    if (!showLoader) return <></>;
    return (
      <Box m={3}>
        <SuspenseLoader />
      </Box>
    );
  }
  return <>{children}</>;
};

export default SuspenseLoader;
