import { RefObject, useEffect } from 'react';

const useSvgElementHover = <T extends SVGElement>(
  ref: RefObject<T | undefined>,
  tagClass: string,
  watcherClass: string
): void => {
  const setup = (ev: Event) => {
    const children = ref.current?.children;

    if (children) {
      for (const c of children as unknown as T[]) {
        c.style.pointerEvents = 'visiblePainted';
        ref.current?.classList.remove(tagClass);
      }
    }
    tagAllElements((ev as MouseEvent).clientX, (ev as MouseEvent).clientY);
  };

  const tagAllElements = (x: number, y: number) => {
    const items = document.elementsFromPoint(x, y);
    if (items) {
      for (const c of items as T[]) {
        if (c.classList.contains(watcherClass)) {
          ref.current?.classList.add(tagClass);
          c.style.pointerEvents = 'none';
        }
      }
    }
  };

  const removeTags = () => {
    const children = ref.current?.children;

    if (children) {
      for (const c of children as unknown as T[]) {
        c.style.pointerEvents = 'visiblePainted';
        ref.current?.classList.remove(tagClass);
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('mousemove', setup);
      ref.current.addEventListener('mouseleave', removeTags);
    }

    return () => {
      ref.current?.removeEventListener('mousemove', setup);
      ref.current?.removeEventListener('mouseleave', removeTags);
    };
  }, [ref.current]);
};

export default useSvgElementHover;
