import { configureStore } from '@reduxjs/toolkit';
import registrationReducer from './components/youthWorkerRegister/registrationSlice';
import registrationConfigReducer from './components/youthWorkerRegister/registrationConfigSlice';

export const store = configureStore({
  reducer: {
    registration: registrationReducer,
    registrationConfig: registrationConfigReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispach = typeof store.dispatch;
