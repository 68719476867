import {
  Box,
  Button,
  keyframes,
  Slide,
  styled,
  Typography
} from '@mui/material';
import Logo from './assets/Youth Work One - Logo WO.svg';
import { Constants } from './config/constants';
import useCookie from './utils/useCookie';
import UseCachedState from './utils/useCachedState';

const LandingScreen = ({ children }: { children: JSX.Element }) => {
  const [dontShowAgain, setDontShowAgain] = useCookie('dontShowAgain', false);

  const [show, setShow] = UseCachedState('showSplashScreen', false);

  if (dontShowAgain || show) return children;

  return (
    <LandingBackground>
      <Slide direction="down" in timeout={1500}>
        <TopBackgoundContrast />
      </Slide>
      <Box
        component="img"
        sx={{
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
          mb: 6,
          zIndex: 99
        }}
        src={Logo}
      />
      <Slide direction="up" in timeout={1000}>
        <Box
          sx={{
            maxWidth: Constants.bodyMaxWidth,
            zIndex: 99
          }}
        >
          <Typography variant="h2">Welcome to Youth Work One</Typography>
          <Typography variant="h5">
            <p>
              Youth Work One is a one-stop shop for everyone working in the
              youth sector in England, as well as anyone with an interest in the
              latest learning, resources and evidence around best practice and
              impact when working with young people in youth settings.
            </p>
            <p>
              Youth Work One provides a range of easy to use directories of
              resources, funding, events and jobs as well as hosting the
              National Youth Sector Census, NYA Youth Work Register and forums
              to share best practice and support.
            </p>
            <p>
              Users of Youth Work One are encouraged to share their resources,
              events, jobs and funding opportunities that may be of mutual
              benefit to others. Just click the ‘add’ button on each page.
            </p>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <Button color="secondary" onClick={() => setDontShowAgain(true)}>
              Don&apos;t show this message again
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShow(true)}
            >
              Go to Site
            </Button>
          </Box>
        </Box>
      </Slide>
      <TopCornerAnimation />
      <BottomCornerAnimation />
    </LandingBackground>
  );
};

const LandingBackground = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden',
  padding: theme.spacing(6),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.getContrastText(theme.palette.primary.main)
}));

const BottomCornerAnimation = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.blue.main,
  maxWidth: '50vh',
  width: '100vw',
  maxHeight: '50vh',
  height: '100vw',
  borderRadius: '50% 45% 50% 40%',
  position: 'absolute',
  right: '-30vh',
  bottom: '-30vh',
  animationName: drift,
  animationDuration: '7000ms',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear'
}));

const TopCornerAnimation = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  maxWidth: '50vh',
  width: '100vw',
  maxHeight: '50vh',
  height: '100vw',
  borderRadius: '45% 40% 50% 40%',
  position: 'absolute',
  left: '-35vh',
  top: '-35vh',
  animationName: drift,
  animationDuration: '8000ms',
  animationIterationCount: 'infinite',
  animationDelay: '1250ms',
  animationTimingFunction: 'linear'
}));

const TopBackgoundContrast = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  width: '250vw',
  height: '250vw',
  borderRadius: '48% 45% 50% 49%',
  position: 'absolute',
  left: '-100vw',
  top: '-222vw',
  animationName: drift,
  animationDuration: '30000ms',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear'
}));

const drift = keyframes`
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
`;

export default LandingScreen;
