import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  IRegistration,
  IRegistrationApplicationSection
} from '../../interfaces/registration';
import { GenericObject } from '../../utils/extensions';
import { GUID } from '../../interfaces/guid';

export interface IUpdateSectionPayload<
  T extends IRegistrationApplicationSection
> {
  section: keyof IRegistration;
  data: Partial<T>;
}

export interface IUpdateSectionArrayPayload {
  section: keyof IRegistration;
  key: string;
  index: number;
  data: GenericObject;
}

export interface IUpdateReferenceRefereeStatusPayload {
  referenceId: GUID;
  status: string;
}

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: {} as IRegistration,
  reducers: {
    clear: () => {
      return {} as IRegistration;
    },
    prePopulate: (state, action: PayloadAction<IRegistration>) => {
      return action.payload;
    },
    updateSection: <S extends IRegistrationApplicationSection>(
      state: Draft<IRegistration>,
      action: PayloadAction<IUpdateSectionPayload<S>>
    ) => {
      return {
        ...state,
        [action.payload.section]: {
          ...((state as IRegistration)[
            action.payload.section
          ] as IRegistrationApplicationSection),
          ...action.payload.data
        }
      };
    },
    updateSectionArray: (
      state: Draft<IRegistration>,
      action: PayloadAction<IUpdateSectionArrayPayload>
    ) => {
      const origArray = (state[action.payload.section] as GenericObject)[
        action.payload.key as keyof GenericObject
      ] as Array<GenericObject>;
      origArray[action.payload.index] = {
        ...origArray[action.payload.index],
        ...action.payload.data
      };
      /* return {
        ...state,
        [action.payload.section]: {
          ...((state as IRegistration)[
            action.payload.section as keyof IRegistration
            ] as IRegistrationApplicationSection),
          [action.payload.key]: {
            ...((
              (state as IRegistration)[
                action.payload.section as keyof IRegistration
                ] as IRegistrationApplicationSection
            )[
              action.payload.key as keyof IRegistrationApplicationSection
              ] as object),
            [action.payload.key]: origArray
          }
        }
      }; */
    },
    updateReferenceRefereeStatus: (
      state: Draft<IRegistration>,
      action: PayloadAction<IUpdateReferenceRefereeStatusPayload>
    ) => {
      const origArray = state.references.references;
      const refIndex = origArray.findIndex(
        (r) => r.id === action.payload.referenceId
      );
      origArray[refIndex] = {
        ...origArray[refIndex],
        status: action.payload.status
      };
    },
    updateStatus: (
      state: Draft<IRegistration>,
      action: PayloadAction<{ [key: string]: string }>
    ) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (key in state) {
          if (key === 'status') state.status = value;
          else
            (
              state[
                key as keyof IRegistration
              ] as IRegistrationApplicationSection
            ).status = value;
        }
      });
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  clear,
  prePopulate,
  updateSection,
  updateSectionArray,
  updateReferenceRefereeStatus,
  updateStatus
} = registrationSlice.actions;

export default registrationSlice.reducer;
