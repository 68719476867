import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  AccountTree,
  BarChart,
  Business,
  Groups,
  Menu as MenuIcon,
  Person,
  PersonSearch,
  RotateRight,
  Rule,
  SnippetFolder
} from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import WorkIcon from '@mui/icons-material/Work';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Link as RouterLink } from 'react-router-dom';
import { SignInButton, SignOutButton } from '../components/auth';
import logo from '../assets/Youth Work One - Logo WO.svg';
import smallLogo from '../assets/favicon-32x32.png';
import { MouseEvent, useRef, useState } from 'react';
import { useIsUserSystemAdmin } from '../utils/userHooks';
import useIntersection from '../utils/useIntersection';
import { Constants } from '../config/constants';

function Header() {
  const { accounts } = useMsal();

  const username = accounts[0] && accounts[0].name;

  const toolBarRef = useRef<HTMLDivElement>(null);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isToolBarInView = useIntersection(toolBarRef, '0px');

  return (
    <AppBar
      position="static"
      sx={{
        flex: '0 1 auto',
        zIndex: 500
      }}
    >
      <Toolbar
        ref={toolBarRef}
        sx={{
          width: '100%',
          maxWidth: Constants.bodyMaxWidth,
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'row',
          padding: '0 1rem !important',
          marginBottom: isToolBarInView ? 0 : '34px !important'
        }}
      >
        <Link
          underline="hover"
          color="inherit"
          variant="h6"
          component={RouterLink}
          to="/"
          sx={{ flexGrow: 1, padding: '1rem 0' }}
        >
          <Box
            sx={{
              maxHeight: {
                xs: '3rem',
                md: '5rem'
              },
              verticalAlign: 'middle'
            }}
            component="img"
            src={logo}
            alt="Youth Work One"
          />
        </Link>
        <UnauthenticatedTemplate>
          <SignInButton showText={isDesktop} />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <Button
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <img src={smallLogo} alt="YWO" width="24px" height="24px" />
            {isDesktop && (
              <Typography variant="button" ml={'0.5rem'}>
                {username}
              </Typography>
            )}
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
          >
            <SignOutButton />
          </Menu>
        </AuthenticatedTemplate>
      </Toolbar>
      <LowerHeader sticky={!isToolBarInView} />
    </AppBar>
  );
}

interface ILowerHeaderProps {
  sticky: boolean;
}

const LowerHeader = ({ sticky }: ILowerHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSysAdmin = useIsUserSystemAdmin();

  return (
    <LowerHeaderBackground
      style={{
        position: sticky ? 'fixed' : 'relative',
        top: 0,
        width: '100%',
        maxHeight: '34px',
        zIndex: 699
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: Constants.bodyMaxWidth,
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'row',
          padding: '0 1rem'
        }}
      >
        <Box sx={{ flex: '1 1 auto' }}></Box>
        <IconButton
          size="small"
          aria-label="main-menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem component={RouterLink} to="/resources">
            <ListItemIcon>
              <SnippetFolder />
            </ListItemIcon>
            <ListItemText>Resources</ListItemText>
          </MenuItem>
          <MenuItem component={RouterLink} to="/curriculum">
            <ListItemIcon>
              <RotateRight />
            </ListItemIcon>
            <ListItemText>Curriculum</ListItemText>
          </MenuItem>
          <MenuItem component={RouterLink} to="/census">
            <ListItemIcon>
              <Rule />
            </ListItemIcon>
            <ListItemText>Census</ListItemText>
          </MenuItem>
          <MenuItem component={RouterLink} to="/events">
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText>Events</ListItemText>
          </MenuItem>
          <MenuItem component={RouterLink} to="/training">
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText>Training</ListItemText>
          </MenuItem>
          <MenuItem component={RouterLink} to="/jobsBoard">
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText>Jobs Board</ListItemText>
          </MenuItem>
          <MenuItem component={RouterLink} to="/funders">
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText>Funding</ListItemText>
          </MenuItem>
          <MenuItem component={RouterLink} to="/youthworkerregister">
            <ListItemIcon>
              <Groups />
            </ListItemIcon>
            <ListItemText>Youth Worker Register</ListItemText>
          </MenuItem>
          <AuthenticatedTemplate>
            <MenuItem
              component={RouterLink}
              to="/youthworkerregister/registrationVerification"
            >
              <ListItemIcon>
                <PersonSearch />
              </ListItemIcon>
              <ListItemText>Search Youth Worker</ListItemText>
            </MenuItem>
          </AuthenticatedTemplate>
          <MenuItem component={RouterLink} to="/datahub">
            <ListItemIcon>
              <BarChart />
            </ListItemIcon>
            <ListItemText>Data Hub</ListItemText>
          </MenuItem>
          {isSysAdmin && <Divider />}
          {isSysAdmin && (
            <MenuItem component={RouterLink} to="/administration">
              <ListItemIcon>
                <AccountTree />
              </ListItemIcon>
              <ListItemText>Administration</ListItemText>
            </MenuItem>
          )}

          <AuthenticatedTemplate>
            <Divider />
            <MenuItem component={RouterLink} to="/me">
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText>My Profile</ListItemText>
            </MenuItem>
            <MenuItem component={RouterLink} to="/organisation">
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText>My Organisation</ListItemText>
            </MenuItem>
          </AuthenticatedTemplate>
        </Menu>
      </Box>
    </LowerHeaderBackground>
  );
};

const LowerHeaderBackground = styled('div')({
  backgroundColor: 'rgba(73,177,133,1)',
  background:
    'linear-gradient(90deg, rgba(73, 177, 113, 1) 0%, rgba(51, 139, 197, 1) 33%, rgba(73, 177, 113, 1) 66%, rgba(51, 139, 197, 1) 100%)'
});

export default Header;
